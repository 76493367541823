import React from 'react';
import './TabSandro.css';
import MailForm from "../components/MailForm";


const TabSandro: React.FC = () => {
    return (
        <MailForm recipientName={'Sandro'} recipientType={'sandro'} />
    );
};

export default TabSandro;
