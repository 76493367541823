import {IonButton, IonItem, IonLabel, IonTextarea, useIonToast} from "@ionic/react";
import React, {useEffect, useState} from "react";
import MainPage from "./MainPage";

interface ContainerProps {
    recipientName: string,
    recipientType: string;
}

const MailForm: React.FC<ContainerProps> = ({recipientName, recipientType}) => {

    const [present] = useIonToast();
    const [showLoading, setShowLoading] = useState(false);

    const [textToSend, setTextToSend] = useState<string>();
    const [apiResponseMessage, setApiResponseMessage] = useState<string>();

    const presentToast = (text: string, isError?: boolean) => {
        let promise = present({
            message: text,
            duration: 1500,
            position: 'bottom',
            color: isError ? 'danger' : 'success'
        });
    };

    useEffect(() => {
        if (apiResponseMessage?.length) {
            presentToast(apiResponseMessage);
        }
    }, [apiResponseMessage])



    const sendTodo = async () => {

        if (!textToSend || textToSend.length <= 1) {
            presentToast("Es wurde noch kein Text für " + recipientName + " eingegeben!", true);
            return;
        }

        setShowLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                text: textToSend,
                recipientType: recipientType
            })
        };

        fetch(`/api/public/sendMail/`, requestOptions)
            .then((response) => response.json())
            .then((actualData) => {
                setApiResponseMessage(actualData);
                setTextToSend("");
                setShowLoading(false);
            });
    };


    return (
        <MainPage>

            <IonItem>
                <IonLabel position="floating">Text zum an {recipientName} senden ...</IonLabel>
                <IonTextarea value={textToSend} rows={10} placeholder="Hier kommt der Text..." onIonChange={e => setTextToSend(e.detail.value!)} />
            </IonItem>

            <IonButton expand="block" onClick={() => sendTodo()}>An {recipientName} senden</IonButton>

        </MainPage>
    );
};

export default MailForm;
