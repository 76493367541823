import React from 'react';
import './TabJessica.css';
import MailForm from "../components/MailForm";


const TabJessica: React.FC = () => {
    return (
        <MailForm recipientName={'Jessica'} recipientType={'jessica'} />
    );
};

export default TabJessica;
