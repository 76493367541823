import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLoading, IonPage, IonProgressBar, IonTitle, IonToolbar, useIonToast} from "@ionic/react";
import {refreshOutline} from "ionicons/icons";
import React, {useEffect, useState} from "react";

interface ContainerProps {
    children: React.ReactNode;
}

const MainPage: React.FC<ContainerProps> = ({children}) => {

    const [present] = useIonToast();
    const [showLoading, setShowLoading] = useState(false);

    const [textToSend, setTextToSend] = useState<string>();
    const [apiResponseMessage, setApiResponseMessage] = useState<string>();

    const presentToast = (text: string, isError?: boolean) => {
        let promise = present({
            message: text,
            duration: 1500,
            position: 'bottom',
            color: isError ? 'danger' : 'success'
        });
    };

    useEffect(() => {
        if (apiResponseMessage?.length) {
            presentToast(apiResponseMessage);
        }
    }, [apiResponseMessage])


    const refreshTool = async () => {
        console.log("REFRESH");
        window.postMessage({type: 'SKIP_WAITING'});
        window.location.reload();
    };


    return (
        <IonPage>

            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="primary">
                        <IonButton onClick={() => refreshTool()}>
                            <IonIcon slot="icon-only" icon={refreshOutline} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>ToDo (v2024-08-19)</IonTitle>
                </IonToolbar>
                {showLoading ? <IonProgressBar type="indeterminate" color="success" /> : null}
            </IonHeader>

            <IonContent fullscreen>

                {children}

                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={5000}
                />

            </IonContent>
        </IonPage>
    );
};

export default MainPage;
